// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-comparisons-getsatisfaction-vs-suggested-js": () => import("./../../../src/pages/comparisons/getsatisfaction-vs-suggested.js" /* webpackChunkName: "component---src-pages-comparisons-getsatisfaction-vs-suggested-js" */),
  "component---src-pages-comparisons-headway-vs-suggested-js": () => import("./../../../src/pages/comparisons/headway-vs-suggested.js" /* webpackChunkName: "component---src-pages-comparisons-headway-vs-suggested-js" */),
  "component---src-pages-comparisons-trello-vs-suggested-js": () => import("./../../../src/pages/comparisons/trello-vs-suggested.js" /* webpackChunkName: "component---src-pages-comparisons-trello-vs-suggested-js" */),
  "component---src-pages-comparisons-uservoice-vs-suggested-js": () => import("./../../../src/pages/comparisons/uservoice-vs-suggested.js" /* webpackChunkName: "component---src-pages-comparisons-uservoice-vs-suggested-js" */),
  "component---src-pages-docs-1-js": () => import("./../../../src/pages/docs1.js" /* webpackChunkName: "component---src-pages-docs-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-intercom-js": () => import("./../../../src/pages/integrations/intercom.js" /* webpackChunkName: "component---src-pages-integrations-intercom-js" */),
  "component---src-pages-integrations-slack-js": () => import("./../../../src/pages/integrations/slack.js" /* webpackChunkName: "component---src-pages-integrations-slack-js" */),
  "component---src-pages-learn-changelog-js": () => import("./../../../src/pages/learn/changelog.js" /* webpackChunkName: "component---src-pages-learn-changelog-js" */),
  "component---src-pages-learn-feedback-js": () => import("./../../../src/pages/learn/feedback.js" /* webpackChunkName: "component---src-pages-learn-feedback-js" */),
  "component---src-pages-learn-roadmap-js": () => import("./../../../src/pages/learn/roadmap.js" /* webpackChunkName: "component---src-pages-learn-roadmap-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-doc-js": () => import("./../../../src/templates/doc.js" /* webpackChunkName: "component---src-templates-doc-js" */)
}

