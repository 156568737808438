/**
 * Implement Gatsby's Browser APIs in this file.
 * 
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

 // You can delete this file if you're not using it
 require("prismjs/themes/prism-solarizedlight.css")
 require("./src/base.css")
 require("./src/index.css")
 require("./src/mobile.css")
